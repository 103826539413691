import React, { useEffect, useRef, useState } from "react";
import Visadebitcard from "../../assets/images/Visadebitcard.jpg";
import MasterCard from "../../assets/images/master card.jpg";
import AmricanExpress from "../../assets/images/amrican_express.jpg";
import SimpleDialogDemo from "../../components/popup/Success";
import Success from "../../assets/images/success.png";
import Failure from "../../assets/images/failure.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { API_BASE_URL, bString } from "../../config/constants";
import { basysPayment, createCustomer } from "../../payment_gateways/basys";
import { handlePayment, handleSaveCard } from "../../payment_gateways/moneris";
import { storeLog } from "../../utils";

var sendrespons = {};
var responseData = {};

export default function Home() {
  const [savecardflag, setCardFlag] = useState(false);
  const Opensavecardpopup = () => {
    setShowCardSaveModal(true);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [guid, setGuid] = useState(searchParams.get("id"));
  const [corpid, setCorpid] = useState(searchParams.get("corpid"));
  const [paymentProcessor, setPaymentProcessor] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [endpoint, setEndpoint] = useState("");
  const [token, setToken] = useState("");
  const [vaultTerms, setVaultTerms] = useState("");
  const [invoiceItems, setInvoiceItems] = useState([]);
  let [isOpen, setISOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, Setdata] = useState();
  const [paynow, SetPaynow] = useState();
  const [itemtype, SetItemType] = useState();
  const [isCardSaveOpen, setIsCardSaveOpen] = useState(false);
  const [accName, setAccName] = useState("");
  const [groupedInvoices, setGroupedInvoices] = useState([]);
  const [showCardSaveModal, setShowCardSaveModal] = useState(false);
  const [cardHasBeenSaved, setCardHasBeenSaved] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [basysApiToken, setBasysApiToken] = useState("");
  //const [basysCustomerID, setBasysCustomerId] = useState("");
  const [basysAccountInfo, setBasysAccountInfo] = useState({});
  const [failedIsOpen, setFailedIsOpen] = useState(false);
  const [monerisPaymentOptions, setMonerisPaymentOptions] = useState({
    FirstNameSet: "false",
    LastNameSet: "false",
    PhoneNumSet: "false",
    EmailSet: "false",
    BillingAddressSet: "false",
  });
  const [monerisSaveCardOptions, setMonerisSaveCardOptions] = useState({
    FirstNameSet: "true",
    LastNameSet: "true",
    PhoneNumSet: "false",
    EmailSet: "true",
    BillingAddressSet: "false",
  });
  const [isBasysTesting, setIsBasysTesting] = useState(true);
  const [basysBillingAddrInfo, setBasysBillingAddrInfo] = useState({});
  const [paymentFailureReason, setPaymentFailureReason] = useState("");
  const [isCardSaveFailedOpen, setIsCardSaveFailedOpen] = useState(false);

  const basysCustomerID = useRef("");

  const invoicesListForPut = useRef([]);

  const checkboxRef = useRef();

  const [formData, setFormData] = useState({
    amount: "",
    first_name: "",
    last_name: "",
    email: "",
    cardHolderName: "",
    cardNumber: "",
    expiry: "",
    cvv: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postalCode: "",
    province: "",
  });

  // Helper function to convert Base64 to ArrayBuffer
  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  // Decrypt function using the Web Cryptography API
  const decrypt = async (encrypted, key, iv) => {
    const keyBuffer = base64ToArrayBuffer(key);
    const ivBuffer = base64ToArrayBuffer(iv);
    const encryptedData = base64ToArrayBuffer(encrypted);

    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      keyBuffer,
      { name: "AES-CBC", length: 128 },
      false,
      ["decrypt"]
    );

    const decrypted = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv: ivBuffer },
      cryptoKey,
      encryptedData
    );

    return new TextDecoder().decode(new Uint8Array(decrypted));
  };

  const fetchEndpointAndLogo = async () => {
    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/logo-and-endpoints?corp_id=${corpid}&pg_token=uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo`
      );
      const { endpoint, logo_uri } = data;

      const key = bString.substring(169, 193);
      const iv = bString.substring(237, 261);

      const imageUrl = await decrypt(logo_uri, key, iv);
      setImageUrl(imageUrl);

      const endpointVal = await decrypt(endpoint.uri, key, iv);
      setEndpoint(endpointVal);
      const token = await decrypt(endpoint.token, key, iv);
      setToken(token);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    fetchEndpointAndLogo();
  }, []);

  useEffect(() => {
    // Select the existing favicon element or create a new one if it does not exist
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }

    // Set the favicon image URL
    link.href = `${imageUrl}${corpid}.png`;

    // Optional: Clean up function if component unmounts
    return () => {
      if (link) {
        document.head.removeChild(link);
      }
    };
  }, [imageUrl]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;

    // Apply formatting for credit card number
    if (name === "cardNumber" && value.length <= 16) {
      // Remove non-digit characters
      updatedValue = value.replace(/\D/g, "");
      // Add hyphens after every 4 digits
      updatedValue = updatedValue.replace(/(\d{4})/g, "$1-");
      // Remove any trailing hyphen
      updatedValue = updatedValue.replace(/-$/, "");
    }

    if (name === "expiry") {
      updatedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      updatedValue = updatedValue.slice(0, 5); // Limit length to 5 characters
      // Ensure month does not exceed 12
      const month = parseInt(updatedValue.slice(0, 2), 10);
      if (month > 12) {
        updatedValue = "12" + updatedValue.slice(2);
      }
      // Ensure year does not exceed 99
      let year = parseInt(updatedValue.slice(2), 10);
      const currentYear = new Date().getFullYear() % 100; // Get last two digits of the current year
      if (year > 99) {
        year = Math.min(year, currentYear + 1); // Limit year to current year or next year
        updatedValue =
          updatedValue.slice(0, 2) + year.toString().padStart(2, "0");
      }
      // Add slash after 2nd character if needed
      if (updatedValue.length > 2 && !updatedValue.includes("/")) {
        updatedValue = updatedValue.slice(0, 2) + "/" + updatedValue.slice(2);
      }
      // Ensure no additional numbers can be entered after the expiry date is complete
      if (updatedValue.length === 5) {
        const [mm, yy] = updatedValue.split("/");
        if (mm.length === 2 && yy.length === 2) {
          updatedValue = mm + "/" + yy;
        }
      }
    }

    // Limit input length for cvv
    if (name === "cvv") {
      updatedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      if (updatedValue.length > 4) {
        updatedValue = updatedValue.substring(0, 4); // Limit length to 4 digits
      }
    }

    setFormData({ ...formData, [name]: updatedValue });
    setErrors({ ...errors, [name]: "" }); // Clear error when user types
  };

  const groupByCheckoutId = async (invoices) => {
    const grouped = {};

    // Grouping objects by MonerisPaymentChechoutID
    invoices.forEach((credential, index) => {
      const id = credential.MonerisAPICredential.MonerisPaymentChechoutID;
      if (!grouped[id]) {
        grouped[id] = [];
      }
      grouped[id].push({
        index,
        ...credential,
      });
    });

    // Creating an array of objects based on grouped data
    const result = Object.keys(grouped).map((key) => ({
      MonerisAPICredential: grouped[key][0].MonerisAPICredential,
      invoices: grouped[key],
    }));

    setGroupedInvoices(result);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${endpoint}/Get?corpid=${corpid}&id=${guid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            Origin_Custom: endpoint,
          },
        }
      );

      sendrespons = response.data;

      setAccName(response.data.PayerAccount.AccountName);
      setPaymentProcessor(response.data.PaymentProcessor);

      const isTesting = response.data.IsTesting;
      if (response.data.PaymentProcessor === 1) {
        if (isTesting === "true") {
          setIsBasysTesting(true);
        } else if (isTesting === "false") {
          setIsBasysTesting(false);
        }
      }

      const payerAccount = response.data.PayerAccount;

      setBasysBillingAddrInfo({
        first_name: payerAccount?.AccountFirstName,
        last_name: payerAccount?.AccountLastName,
        company: payerAccount?.AccountName,
        line_1: payerAccount?.Address?.Line1,
        line_2: payerAccount?.Address?.Line2,
        city: payerAccount?.Address?.City,
        state: payerAccount?.Address?.Province,
        postal_code: payerAccount?.Address?.PostalCode ?? "", //Temporory hide not working
        country: payerAccount?.Address.Country ?? "",
        email: payerAccount?.AccountEmail ?? "",
        phone: payerAccount?.PhoneNum ?? "",
        fax: "",
      });

      setCurrencyCode(response.data.CurrencyCode);
      setVaultTerms(response.data.ThePayNowOptions.VaultTermsConditions);
      SetPaynow(response.data.ThePayNowOptions);
      SetItemType(response.data.ItemType);
      setMonerisPaymentOptions(response.data.MonerisPaymentCheckoutIDConfig);
      setMonerisSaveCardOptions(
        response.data.MonerisCreditCardCheckoutIDConfig
      );
      setBasysApiToken(response.data?.BasysAPICredential?.APIToken);
      //      setBasysApiToken("api_2BaAkMlujNSu11icqAUlpBwyb1i");  /*For Production Testing Api Key*/
      basysCustomerID.current = response.data?.BasysCustomerID;
      //setBasysCustomerId(response.data?.BasysCustomerID);
      setBasysAccountInfo(response.data?.PayerAccount);

      let selection = [];
      if (response.data.ItemType == 0) {
        response.data.InvoiceItems.forEach((item, index) => {
          if (item.IsDefault === "true" && item.AmountDue !== "0") {
            selection.push(index);
          }
        });
        Setdata(response.data.InvoiceItems);
        setInvoiceItems([...response.data.InvoiceItems]);
        groupByCheckoutId(response.data.InvoiceItems);
      } else if (response.data.ItemType == 1) {
        Setdata(response.data.CiaByWeekItems);
        response.data.CiaByWeekItems.forEach((item, index) => {
          if (item.IsDefault && item.AmountDue !== "0") {
            selection.push(index);
          }
        });
        setInvoiceItems([...response.data.CiaByWeekItems]);
      } else if (response.data.ItemType == 2) {
        Setdata(response.data.CiaByMonthItems);
        response.data.CiaByMonthItems.forEach((item, index) => {
          if (item.IsDefault && item.AmountDue !== "0") {
            selection.push(index);
          }
        });
        setInvoiceItems([...response.data.CiaByMonthItems]);
      } else if (response.data.ItemType == 3) {
        Setdata(response.data.CiaPrepayItems);
        response.data.CiaPrepayItems.forEach((item, index) => {
          if (item.IsDefault && item.AmountDue !== "0") {
            selection.push(index);
          }
        });
        setInvoiceItems([...response.data.CiaPrepayItems]);
      } else {
        console.log("itemtype not found!");
      }

      setSelectedRows(selection);

      storeLog(
        "info",
        `${JSON.stringify(response.data)}`,
        response.status,
        guid,
        corpid,
        "GET call",
        `GET call for ${response.data.PayerAccount.AccountName}`,
        "Success"
      );

      setLoading(false);
    } catch (error) {
      storeLog(
        "error",
        `Error fetching ${endpoint}/Get?corpid=${corpid}&id=${guid}`,
        500,
        guid,
        corpid,
        "GET call",
        `GET call for ${accName}`,
        "Failure"
      );
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const calculateTotal = () => {
    let total = 0;
    selectedRows.forEach((index) => {
      const currentItem = data[index];
      if (currentItem.hasOwnProperty("EditedAmountDue")) {
        total += parseFloat(currentItem.EditedAmountDue);
      } else {
        total += parseFloat(currentItem.AmountDue / 100);
      }
    });
    return total.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    if (selectedRows.includes(index)) {
      console.log(
        "remove Selected row--->",
        selectedRows.filter((item) => item !== index)
      );
      setSelectedRows(selectedRows.filter((item) => item !== index));
    } else {
      console.log("add Selected row--->", [...selectedRows, index]);
      setSelectedRows([...selectedRows, index]);
    }
    setSelectAll(selectedRows.length + 1 === data.length);
  };

  // Handle select all checkbox change
  const handleSelectAllChange = () => {
    if (!selectAll) {
      const allIndices = data.map((_, index) => index);
      setSelectedRows(allIndices);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(!selectAll);
  };

  const handleAmountChange = (e, index, editing) => {
    if (editing && !selectedRows.includes(index)) {
      handleCheckboxChange(index);
    }

    const value = editing
      ? e.target.value
      : parseFloat(e.target.value.replace(",", ""));
    const updatedData = [...data];

    if (!isNaN(value) && value <= data[index].AmountDue / 100) {
      updatedData[index].EditedAmountDue = value;
      const valueToSend = (value * 100).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      });
      updatedData[index].PaymentResponse.PaymentAmount = valueToSend;
      Setdata(updatedData);
    }
    if (value < 0.01) {
      // Deselect the corresponding checkbox
      setSelectedRows(selectedRows.filter((item) => item !== index));
      setSelectAll(false); // Uncheck the "Select All" checkbox
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

    // Pad day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  function handelCancel() {
    setISOpen(false);
    window.location.href = `/?corpid=${corpid}&id=${guid}`;
  }

  const getCardId = async () => {
    let payment_method_id = "";
    const cardNumber = formData.cardNumber.replace(/-/g, "");
    const maskedNum = `${cardNumber.substring(
      0,
      6
    )}******${cardNumber.substring(12)}`;
    const expiration_date = formData.expiry;

    const response = await axios.get(
      `${API_BASE_URL}/get-vault-customer?action_type=GetCardIdGetCall&pg_token=uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo&customer_id=${basysCustomerID.current}&IsTesting=${isBasysTesting}`,
      {
        headers: {
          Authorization: basysApiToken,
          "Content-Type": "application/json",
        },
      }
    );
    let cards = [];

    if (response?.data?.data?.data?.customer?.payments?.cards) {
      cards = response?.data?.data?.data?.customer?.payments?.cards;
    } else {
      return "";
    }

    cards.forEach((item) => {
      if (
        item.masked_number === maskedNum &&
        item.expiration_date == expiration_date
      ) {
        payment_method_id = item.id;
        return;
      }
    });
    console.log("payment_method_id--->", payment_method_id);
    return payment_method_id;
  };

  const enableSaveButton = () => {
    if (paymentProcessor === 0) {
      if (
        isCheck &&
        formData.first_name &&
        formData.last_name &&
        formData.cardHolderName &&
        formData.cardNumber &&
        formData.cvv &&
        formData.expiry
      ) {
        return true;
      }
      return false;
    } else {
      if (
        isCheck &&
        formData.cardHolderName &&
        formData.cardNumber &&
        formData.cvv &&
        formData.expiry
      ) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (showCardSaveModal === true) {
      if (checkboxRef.current && isCheck) {
        checkboxRef.current.checked = true;
      }
    }
  }, [showCardSaveModal]);

  return (
    <>
      <div className="dashboard">
        {/* Dashboard head Start */}
        <div className="dashboard_head">
          <div className="logo">
            <img
              src={`${imageUrl}${corpid}.png`}
              alt="logo"
              style={{ width: "98px", height: "65px" }} // Adjust the width and height as needed
            />
          </div>
          <div className="contect">
            {paynow && paynow.ShowTopBanner && (
              <p>Need Help? {paynow.HelpCenterPhone}</p>
            )}
          </div>
        </div>
        {/* Dashboard head end */}

        {/* Dashbord Body start */}
        <div className="dashboard_body">
          <div className="paymemt_history">
            <div className="paymemt_history_heading">
              <div>
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >{`Invoice Details${accName ? " for " + accName : ""}`}</p>
                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore harum sequi fugiat. </p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> */}
                <div className="subtotale">
                  <strong>
                    {" "}
                    <label>
                      Subtotal ({(selectedRows && selectedRows.length) || 0}{" "}
                      Invoices):{" "}
                    </label>
                    <span>
                      {(selectedRows.length > 0 && (
                        <span>{calculateTotal()}</span>
                      )) ||
                        "$00.00"}
                    </span>
                  </strong>
                </div>
              </div>
              {paynow && paynow.ShowInvDetailsDescription ? (
                <p>{paynow.InvDetailsDescription}</p>
              ) : (
                ""
              )}
            </div>

            <SimpleDialogDemo
              open={isOpen}
              handelCancel={handelCancel}
              ButtonText={"OK"}
              image={Success}
              alt={"successlogo"}
              title={"Payment Processed"}
              decs={
                "You will receive an email confirmation of your payment(s) shortly. If you don’t receive it, please check your Junk folder."
              }
              ButtonHandel={handelCancel}
              color={"#048547"}
            />

            <SimpleDialogDemo
              open={failedIsOpen}
              handelCancel={() => setFailedIsOpen(false)}
              ButtonText={"OK"}
              image={Failure}
              alt={"errorLogo"}
              title={"Payment Failed"}
              decs={paymentFailureReason}
              ButtonHandel={() => {
                setPaymentFailureReason("");
                setFailedIsOpen(false);
              }}
              color={"#048547"}
            />

            <SimpleDialogDemo
              open={isCardSaveOpen}
              handelCancel={() => setIsCardSaveOpen(false)}
              ButtonText={"OK"}
              image={Success}
              alt={"save details"}
              title={"Card Saved"}
              decs={
                "Your card details have been saved.<br/>Future invoices will be processed automatically"
              }
              ButtonHandel={() => {
                setIsCardSaveOpen(false);
                setCardHasBeenSaved(true);
              }}
              color={"#2D4691"}
            />

            <SimpleDialogDemo
              open={isCardSaveFailedOpen}
              handelCancel={() => {
                setPaymentFailureReason("");
                setIsCardSaveFailedOpen(false);
              }}
              ButtonText={"OK"}
              image={Failure}
              alt={"save details"}
              title={"Failed to save card"}
              decs={paymentFailureReason}
              ButtonHandel={() => {
                setIsCardSaveFailedOpen(false);
              }}
              color={"#2D4691"}
            />

            <div className="payment_history_table">
              <table>
                {/* table heading start */}
                <tbody>
                  <tr>
                    {/* <th>No</th> */}
                    <th>
                      {" "}
                      <button onClick={handleSelectAllChange}>
                        {selectAll ? "Deselect All" : "Select All"}
                      </button>
                    </th>
                    <th>Station</th>
                    <th>Contract</th>
                    <th>Invoice</th>
                    <th>Invoice Date</th>
                    <th className="textright amout_width">Invoice Amount</th>
                    <th className="textright">Balance</th>
                    <th>Amount To Pay</th>
                  </tr>
                  {/* table heading End */}
                  {/* table Discription start */}
                  {/* {Array.isArray(data) && data.map(item => ( */}
                  {/* {loading && <Loader />} */}
                  {loading && (
                    <div className="modal-overlay">
                      <div className="api-loading-container">
                        <div id="loader"></div>
                        <p className="loading-text">Loading...</p>
                      </div>
                    </div>
                  )}
                  <div className="loader-container">
                    {!loading && !data && (
                      <p>Data Not Found. Please contact the admin.</p>
                    )}
                  </div>
                  {/* {error && <p>Error: {error}</p>} */}
                  {data &&
                    data.map((item, i) => {
                      // const DueAmount = item.AmountDue;
                      return (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              onChange={() => handleCheckboxChange(i)}
                              checked={selectedRows.includes(i)}
                              style={{
                                opacity:
                                  item.AmountDue === "0" ||
                                  parseFloat(item?.EditedAmountDue) < 0.01
                                    ? 0.5
                                    : 1,
                                pointerEvents:
                                  item.AmountDue === "0" ||
                                  parseFloat(item?.EditedAmountDue) < 0.01
                                    ? "none"
                                    : "auto",
                                cursor:
                                  item.AmountDue === "0" ||
                                  parseFloat(item?.EditedAmountDue) < 0.01
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            />
                          </td>
                          <td>
                            {(item.SummaryInvoiceNumber > 0 && "Multi") ||
                              (item.SummaryInvoiceNumber == 0 &&
                                item.StationName)}
                          </td>{" "}
                          {/* This line might need to be modified based on the structure of your data */}
                          <td>
                            {(item.SummaryInvoiceNumber > 0 && "Multi") ||
                              (item.SummaryInvoiceNumber == 0 &&
                                item.ContractNumber)}
                          </td>
                          <td>
                            {(item.SummaryInvoiceNumber > 0 &&
                              item.SummaryInvoiceNumber) ||
                              (item.SummaryInvoiceNumber == 0 &&
                                item.InvoiceNum)}
                          </td>
                          <td>{formatDate(item.InvoiceDate)}</td>
                          <td className="textright">
                            {(item.InvoiceAmount / 100).toLocaleString(
                              "en-US",
                              { style: "currency", currency: "USD" }
                            )}
                          </td>
                          <td className="textright">
                            {(item.AmountDue / 100).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>
                          <td className="dollar">
                            <div>
                              $
                              <input
                                className="editamount textright"
                                type="text" // Change type to text to display formatted value correctly
                                style={{
                                  opacity: item.AmountDue === "0" ? 0.5 : 1,
                                  pointerEvents:
                                    item.AmountDue === "0" ? "none" : "auto",
                                  cursor:
                                    item.AmountDue === "0"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onBlur={(e) => {
                                  handleAmountChange(e, i, false);
                                }}
                                value={
                                  item.EditedAmountDue !== undefined
                                    ? item.EditedAmountDue.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    : (item.AmountDue / 100).toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                }
                                onChange={(e) => handleAmountChange(e, i, true)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  {/* table Discription End */}
                </tbody>
              </table>
            </div>

            <div className="payment_information_accounting_office">
              {paynow && paynow.ShowBottomBanner && (
                <div>
                  <h2>{paynow.Title}</h2>
                  <p>
                    Phone:{" "}
                    <a
                      style={{
                        color: "var(--font-gray-color)",
                        textDecoration: "none",
                      }}
                      href="tel:+1-416-987-3456"
                    >
                      {paynow.Phone}
                    </a>
                  </p>
                  <p>
                    Email:{" "}
                    <a
                      style={{
                        color: "var(--font-gray-color)",
                        textDecoration: "none",
                      }}
                      href={"mailto:" + paynow.Email}
                    >
                      {paynow.Email}
                    </a>
                  </p>
                  <p>
                    Address: {paynow.Address} {paynow.City}, {paynow.Prov},{" "}
                    {paynow.Postal}
                  </p>
                </div>
              )}
              {!cardHasBeenSaved && (
                <div className="card_vault">
                  <div>
                    <button onClick={Opensavecardpopup}>Store card</button>
                  </div>
                  <p>
                    Securely store your credit card, for automatic <br />
                    monthly payments of future invoices
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* card details */}

          <div className="payment_information">
            <div className="payment_information_heading">
              <div className="payment_information_heading_details">
                <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                  Payment Information
                </p>
                <div className="paymemt_card">
                  <img src={Visadebitcard} alt="Visadebitcard" />
                  <img src={MasterCard} alt="MasterCard" />
                  <img src={AmricanExpress} alt="AmricanExpress" />
                </div>
              </div>
            </div>
            <form>
              <div className="formdetails" id="formdetails">
                {monerisPaymentOptions?.FirstNameSet === "true" && (
                  <>
                    <label>First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      placeholder="Enter First Name"
                    />
                    {errors.first_name && (
                      <p className="error">{errors.first_name}</p>
                    )}
                  </>
                )}
                {monerisPaymentOptions?.LastNameSet === "true" && (
                  <>
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      placeholder="Enter Last Name"
                    />
                    {errors.last_name && (
                      <p className="error">{errors.last_name}</p>
                    )}
                  </>
                )}

                {monerisPaymentOptions?.PhoneNumSet === "true" && (
                  <>
                    <label>Phone</label>
                    <input
                      type="phone"
                      name="phone"
                      maxLength="11"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Phone"
                    />
                  </>
                )}
                <div className="card_info_tag">
                  <hr />
                  <p>Card Information</p>
                </div>

                <label>Cardholder Name</label>
                <input
                  autocomplete="cc-name"
                  type="text"
                  name="cardHolderName"
                  value={formData.cardHolderName}
                  onChange={handleChange}
                  placeholder="Enter cardholder Name"
                />
                {!showCardSaveModal && errors.cardHolderName && (
                  <p className="error">{errors.cardHolderName}</p>
                )}

                <label>Card Number</label>
                <input
                  autocomplete="cc-number"
                  type="tel"
                  name="cardNumber"
                  pattern="[\d| ]{16,22}"
                  maxLength="19"
                  value={formData.cardNumber}
                  onChange={handleChange}
                  placeholder="0000-0000-0000-0000"
                />
                {!showCardSaveModal && errors.cardNumber && (
                  <p className="error">{errors.cardNumber}</p>
                )}

                <div className="card_cvv_expiry">
                  <div className="card_expiry">
                    <label>Expiry</label>
                    <input
                      autocomplete="cc-exp"
                      type="tel"
                      name="expiry"
                      value={formData.expiry}
                      onChange={handleChange}
                      placeholder="MM/YY"
                    />
                    {!showCardSaveModal && errors.expiry && (
                      <p className="error">{errors.expiry}</p>
                    )}
                  </div>
                  <div className="card_cvv">
                    <label>CVV</label>
                    <input
                      autocomplete="cc-csc"
                      type="tel"
                      name="cvv"
                      value={formData.cvv}
                      onChange={handleChange}
                      placeholder="CVV"
                    />
                    {!showCardSaveModal && errors.cvv && (
                      <p className="error">{errors.cvv}</p>
                    )}
                  </div>
                </div>
                {monerisPaymentOptions?.BillingAddressSet === "true" && (
                  <>
                    <div className="card-information-header">
                      <hr />
                      <p className="card-information-header-text">
                        Billing Address
                      </p>
                    </div>
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      name="addressLine1"
                      value={formData.addressLine1}
                      onChange={handleChange}
                      placeholder="Address Line 1"
                    />
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      name="addressLine2"
                      value={formData.addressLine2}
                      onChange={handleChange}
                      placeholder="Address Line 2"
                    />
                    <label>City</label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      placeholder="City"
                    />
                    <label>Country</label>
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      placeholder="Country"
                    />
                    <label>Postal Code</label>
                    <input
                      type="text"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                      placeholder="Postal Code"
                    />
                    <label>Province</label>
                    <input
                      type="text"
                      name="province"
                      value={formData.province}
                      onChange={handleChange}
                      placeholder="Province"
                    />
                  </>
                )}
                <button
                  className={
                    !selectedRows?.length ||
                    !formData.cardHolderName ||
                    !formData.cardNumber ||
                    !formData.cvv ||
                    !formData.expiry
                      ? "save-card-button-disabled"
                      : ""
                  }
                  type="submit"
                  onClick={(e) => {
                    (paymentProcessor === 0 &&
                      handlePayment(
                        e,
                        accName,
                        formData,
                        paynow,
                        currencyCode,
                        monerisPaymentOptions,
                        setLoading,
                        endpoint,
                        guid,
                        corpid,
                        token,
                        groupedInvoices,
                        setFailedIsOpen,
                        setISOpen,
                        selectedRows,
                        sendrespons,
                        invoiceItems,
                        invoicesListForPut,
                        data,
                        setErrors,
                        setPaymentFailureReason
                      )) ||
                      basysPayment(
                        e,
                        accName,
                        getCardId,
                        basysCustomerID,
                        setLoading,
                        invoiceItems,
                        basysAccountInfo,
                        paynow,
                        isBasysTesting,
                        basysBillingAddrInfo,
                        formData,
                        basysApiToken,
                        selectedRows,
                        sendrespons,
                        endpoint,
                        guid,
                        corpid,
                        token,
                        setFailedIsOpen,
                        setISOpen,
                        data,
                        setShowCardSaveModal,
                        setIsCardSaveOpen,
                        setCardHasBeenSaved,
                        setErrors,
                        setPaymentFailureReason,
                        setIsCardSaveFailedOpen
                      );
                  }}
                >
                  Process Payment
                </button>
                {/* <p id="terms"><b>©2024</b> Maritime Broadcast Systems</p> */}
              </div>
            </form>
          </div>
        </div>

        {/* Dashbard Body End */}

        {showCardSaveModal && (
          <div
            className="modal-overlay"
            onClick={() => setShowCardSaveModal(false)}
          >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="popup_description">
                <h2 className="payment-terms-heading">Payment Terms</h2>
                <p className="payment-terms-text">{vaultTerms}</p>
                <div className="popup_check_terms_condition">
                  <input
                    ref={checkboxRef}
                    type="checkbox"
                    name="checkbox"
                    id
                    onChange={() => setIsCheck(!isCheck)}
                  />
                  <span>I accept the above Payment Terms</span>
                </div>
                <hr className="payment-terms-separator" />
              </div>
              <form>
                <div className="card-save-modal-form">
                  {monerisSaveCardOptions?.FirstNameSet === "true" && (
                    <>
                      <label>First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        placeholder="Enter First Name"
                      />
                      {errors.first_name && (
                        <p className="error">{errors.first_name}</p>
                      )}
                    </>
                  )}

                  {monerisSaveCardOptions?.LastNameSet === "true" && (
                    <>
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        placeholder="Enter Last Name"
                      />
                      {errors.last_name && (
                        <p className="error">{errors.last_name}</p>
                      )}
                    </>
                  )}

                  {monerisSaveCardOptions?.PhoneNumSet === "true" && (
                    <>
                      <label>Phone</label>
                      <input
                        type="phone"
                        name="phone"
                        maxLength="11"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone"
                      />
                    </>
                  )}
                  <div className="card-information-header">
                    <hr />
                    <p className="card-information-header-text">
                      Card Information
                    </p>
                  </div>

                  <label>Cardholder Name</label>
                  <input
                    autocomplete="cc-name"
                    type="text"
                    name="cardHolderName"
                    value={formData.cardHolderName}
                    onChange={handleChange}
                    placeholder="Enter cardholder Name"
                  />
                  {errors.cardHolderName && (
                    <p className="error">{errors.cardHolderName}</p>
                  )}

                  <label>Card Number</label>
                  <input
                    autocomplete="cc-number"
                    type="tel"
                    name="cardNumber"
                    pattern="[\d| ]{16,22}"
                    maxLength="19"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    placeholder="0000-0000-0000-0000"
                  />
                  {errors.cardNumber && (
                    <p className="error">{errors.cardNumber}</p>
                  )}

                  <div className="card-expiry-cvv-container">
                    <div className="card_expiry">
                      <label>Expiry</label>
                      <input
                        autocomplete="cc-exp"
                        type="tel"
                        name="expiry"
                        value={formData.expiry}
                        onChange={handleChange}
                        placeholder="MM/YY"
                      />
                      {errors.expiry && (
                        <p className="error">{errors.expiry}</p>
                      )}
                    </div>
                    <div className="card_cvv">
                      <label>CVV</label>
                      <input
                        autocomplete="cc-csc"
                        type="tel"
                        name="cvv"
                        value={formData.cvv}
                        onChange={handleChange}
                        placeholder="CVV"
                      />
                      {errors.cvv && <p className="error">{errors.cvv}</p>}
                    </div>
                  </div>
                  {monerisSaveCardOptions?.BillingAddressSet === "true" && (
                    <>
                      <div className="card-information-header">
                        <hr />
                        <p className="card-information-header-text">
                          Billing Address
                        </p>
                      </div>
                      <label>Address Line 1</label>
                      <input
                        type="text"
                        name="addressLine1"
                        value={formData.addressLine1}
                        onChange={handleChange}
                        placeholder="Address Line 1"
                      />
                      <label>Address Line 2</label>
                      <input
                        type="text"
                        name="addressLine2"
                        value={formData.addressLine2}
                        onChange={handleChange}
                        placeholder="Address Line 2"
                      />
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder="City"
                      />
                      <label>Country</label>
                      <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        placeholder="Country"
                      />
                      <label>Postal Code</label>
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        placeholder="Postal Code"
                      />
                      <label>Province</label>
                      <input
                        type="text"
                        name="province"
                        value={formData.province}
                        onChange={handleChange}
                        placeholder="Province"
                      />
                    </>
                  )}
                  <div className="button-pair-container">
                    <button
                      className="cancel-button"
                      onClick={() => {
                        setShowCardSaveModal(false);
                        setIsCheck(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={`save-button ${
                        enableSaveButton() ? "" : "save-card-button-disabled"
                      }`}
                      type="submit"
                      disabled={!isCheck}
                      style={{
                        backgroundColor: enableSaveButton() ? "" : "gray",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (paymentProcessor === 0) {
                          handleSaveCard(
                            accName,
                            formData,
                            paynow,
                            currencyCode,
                            setLoading,
                            endpoint,
                            guid,
                            corpid,
                            token,
                            groupedInvoices,
                            sendrespons,
                            monerisSaveCardOptions,
                            setShowCardSaveModal,
                            setCardFlag,
                            setIsCardSaveOpen
                          );
                        } else {
                          createCustomer(
                            accName,
                            false,
                            basysCustomerID,
                            setLoading,
                            basysAccountInfo,
                            isBasysTesting,
                            basysBillingAddrInfo,
                            formData,
                            basysApiToken,
                            sendrespons,
                            endpoint,
                            guid,
                            corpid,
                            token,
                            setShowCardSaveModal,
                            setIsCardSaveOpen,
                            setCardHasBeenSaved,
                            setErrors,
                            invoiceItems,
                            paynow,
                            selectedRows,
                            setFailedIsOpen,
                            setISOpen,
                            data,
                            setPaymentFailureReason,
                            setIsCardSaveFailedOpen
                          );
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
