import '../src/assets/css/All.css';
import '../src/assets/css/Media.css'
import { Route, BrowserRouter as Router, Routes  } from 'react-router-dom';
import Home from '../src/pages/home/Home';
import Success from '../src/components/popup/Success'
import Hometest from '../src/pages/home/home_test';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/test' element={<Hometest />} />
        <Route path='/success' element={<Success />} />
      </Routes>
    </Router>
  );
}

export default App;
