import axios from "axios";
import {
  calculateTotalForInvoices,
  getFormattedDate,
  storeLog,
  validateForm,
} from "../../utils";
import { API_BASE_URL } from "../../config/constants";

export const basysPayment = async (
  e,
  accName,
  getCardId,
  basysCustomerID,
  setLoading,
  invoiceItems,
  basysAccountInfo,
  paynow,
  isBasysTesting,
  basysBillingAddrInfo,
  formData,
  basysApiToken,
  selectedRows,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token,
  setFailedIsOpen,
  setISOpen,
  data,
  setShowCardSaveModal,
  setIsCardSaveOpen,
  setCardHasBeenSaved,
  setErrors,
  setPaymentFailureReason,
  setIsCardSaveFailedOpen
) => {
  e.preventDefault();

  let payment_method_id = "";

  if (basysCustomerID.current) {
    payment_method_id = await getCardId();
    if (!payment_method_id) {
      await createCustomer(
        accName,
        true,
        basysCustomerID,
        setLoading,
        basysAccountInfo,
        isBasysTesting,
        basysBillingAddrInfo,
        formData,
        basysApiToken,
        sendrespons,
        endpoint,
        guid,
        corpid,
        token,
        setShowCardSaveModal,
        setIsCardSaveOpen,
        setCardHasBeenSaved,
        setErrors,
        invoiceItems,
        paynow,
        selectedRows,
        setFailedIsOpen,
        setISOpen,
        data,
        setPaymentFailureReason,
        setIsCardSaveFailedOpen
      );
      payment_method_id = await getCardId();
    }
    await handleBasysCheckout(
      accName,
      payment_method_id,
      setLoading,
      invoiceItems,
      basysCustomerID,
      basysAccountInfo,
      paynow,
      isBasysTesting,
      basysBillingAddrInfo,
      formData,
      basysApiToken,
      selectedRows,
      sendrespons,
      endpoint,
      guid,
      corpid,
      token,
      setFailedIsOpen,
      setISOpen,
      data,
      setErrors,
      setPaymentFailureReason,
    );
  } else {
    await createCustomer(
      accName,
      true,
      basysCustomerID,
      setLoading,
      basysAccountInfo,
      isBasysTesting,
      basysBillingAddrInfo,
      formData,
      basysApiToken,
      sendrespons,
      endpoint,
      guid,
      corpid,
      token,
      setShowCardSaveModal,
      setIsCardSaveOpen,
      setCardHasBeenSaved,
      setErrors,
      invoiceItems,
      paynow,
      selectedRows,
      setFailedIsOpen,
      setISOpen,
      data,
      setPaymentFailureReason,
      setIsCardSaveFailedOpen
    );
  }
};

const handleBasysCheckout = async (
  accName,
  payment_method_id,
  setLoading,
  invoiceItems,
  basysCustomerID,
  basysAccountInfo,
  paynow,
  isBasysTesting,
  basysBillingAddrInfo,
  formData,
  basysApiToken,
  selectedRows,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token,
  setFailedIsOpen,
  setISOpen,
  data,
  setErrors,
  setPaymentFailureReason,
) => {
  setLoading(true);

  const payload = {
    type: "sale",
    amount: parseFloat(
      calculateTotalForInvoices(
        invoiceItems.map((_, index) => {
          return {
            index,
          };
        }),
        data,
        selectedRows
      )
    ),
    description: basysAccountInfo?.AccountName,
    email_receipt: true,
    email_address: paynow.email,
    create_vault_record: false,
    payment_method: {
      customer: {
        id: basysCustomerID.current,
        payment_method_type: "card",
        payment_method_id,
      },
    },
    pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
    IsTesting: isBasysTesting,
    billing_address: basysBillingAddrInfo,
    guid: guid,
    corp_id: corpid,
    action_type: "Basys Payment handleBasysCheckout"
  };

  if (validateForm(formData, setErrors)) {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api-authenticator-basysiqpro`,
        payload,
        {
          headers: {
            Authorization: basysApiToken,
            "Content-Type": "application/json",
          },
        }
      );

      const responseCode = response.data.data.response_code;

      if (responseCode === 100) {
        // Do nothing
      } else if (responseCode === 99) {
        setPaymentFailureReason(`${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`);
        throw new Error("Payment Pending");
      } else if (responseCode === 110) {
        setPaymentFailureReason(`${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`);
        throw new Error("Payment partially approved");
      } else if (responseCode >= 200 && responseCode <= 299) {
        setPaymentFailureReason(`${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`);
        throw new Error("Payment declined");
      } else if (responseCode >= 300 && responseCode <= 399) {
        setPaymentFailureReason(`${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`);
        throw new Error("Payment declined");
      } else if (responseCode >= 400 && responseCode <= 499) {
        setPaymentFailureReason(`${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`);
        throw new Error("Transaction Processor Error");
      } else {
        setPaymentFailureReason(`${response?.data?.data?.response_code}: ${response?.data?.data?.response} - ${response?.data?.data?.response_body?.card?.processor_response_text}`);
        throw new Error("Payment status unknown");
      }

      if (response.data.msg === "success") {
        let putData = sendrespons; //copy data
        let invoiceItemsTmp = [];
        if (selectedRows.length === 0) {
          setLoading(false);
          putData.InvoiceItems = invoiceItems;
        } else {
          for (let i = 0; i < putData.InvoiceItems.length; i++) {
            if (
              selectedRows.includes(i) &&
              Number(
                putData?.InvoiceItems[i].PaymentResponse?.PaymentAmount.replace(
                  ",",
                  ""
                )
              ) > 0
            ) {
              const updatedPaymentResponse = {
                TransactionDate: getFormattedDate(),
                TransactionID: response?.data?.data?.id,
                ReferenceNumber:
                  response?.data?.data?.referenced_transaction_id,
                PaymentStatus: 1,
                PaymentAmount:
                  putData?.InvoiceItems[i].PaymentResponse?.PaymentAmount,
                ResponseCode:
                  response?.data?.data?.response_body?.card?.response_code,
                ResponseMessage:
                  response?.data?.data?.response_body?.card?.response,
              };
              putData.InvoiceItems[i].PaymentResponse = updatedPaymentResponse;
              invoiceItemsTmp.push(putData.InvoiceItems[i]);
            } else {
              invoiceItemsTmp.push(invoiceItems[i]);
            }
          }
          putData.InvoiceItems = invoiceItemsTmp;
        }
        putData.TheMonerisCC = {
          ...putData.TheMonerisCC,
          CcType: response?.data?.data?.response_body?.card?.card_type,
          Last4Num: response?.data?.data?.response_body?.card?.last_four,
        };
        putData.BasysCustomerID = basysCustomerID.current;
        putData.guid = guid;
        putData.corp_id = corpid;
        try {
          const url = `${endpoint}/Put?id=${guid}`;
          const putResponse = await fetch(url, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "*/*",
              Origin_Custom: endpoint,
            },
            body: JSON.stringify(putData),
          });
          setLoading(false);
          const responseData = await putResponse.json();
          storeLog(
            "info",
            `Basys Payment PUT request: "${JSON.stringify(putData)}"`,
            200,
            guid,
            corpid,
            "Basys Payment",
            `Basys Payment for ${accName}`,
            "Success"
          );
          // Handle the response here as needed
        } catch (error) {
          storeLog(
            "error",
            `Basys Payment PUT request: "${JSON.stringify(putData)}"`,
            200,
            guid,
            corpid,
            "Basys Payment",
            `Basys Payment for ${accName}`,
            "Failure"
          );
          setLoading(false);
          setFailedIsOpen(true);
          console.error("Error fetching data:", error);
          return;
          // Handle error here
        }

        setLoading(false);
        setISOpen(true);
      } else {
        setLoading(false);
        setFailedIsOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setFailedIsOpen(true);
      console.error("Error occurred while making API call:", error);
    }
  } else {
    setLoading(false);
  }
};

// Basys Save card details code start here...
const basysSaveCardPutRequest = async (
  accName,
  basysCustomerID,
  monerisCc,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token
) => {
  let payload = sendrespons;
  try {
    payload.IsCcSaved = true;
    payload.TheMonerisCC = monerisCc;
    payload = {
      ...payload,
      BasysCustomerID: basysCustomerID,
      guid: guid,
      corp_id: corpid,
    };

    const { data, status } = await axios.put(
      `${endpoint}/Put?id=${guid}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Origin_Custom: endpoint,
        },
      }
    );

    storeLog(
      "info",
      `Save Card basys PUT request: "${JSON.stringify(payload)}"`,
      status,
      guid,
      corpid,
      "Save Card",
      `Basys Save Card for ${accName}`,
      "Success"
    );
  } catch (err) {
    storeLog(
      "error",
      `Save Card basys PUT request: "${JSON.stringify(payload)}"`,
      500,
      guid,
      corpid,
      "Save Card",
      `Basys Save Card for ${accName}`,
      "Failure"
    );
    console.log(err);
  }
};

export const createCustomer = async (
  accName,
  fromPayment,
  basysCustomerID,
  setLoading,
  basysAccountInfo,
  isBasysTesting,
  basysBillingAddrInfo,
  formData,
  basysApiToken,
  sendrespons,
  endpoint,
  guid,
  corpid,
  token,
  setShowCardSaveModal,
  setIsCardSaveOpen,
  setCardHasBeenSaved,
  setErrors,
  invoiceItems,
  paynow,
  selectedRows,
  setFailedIsOpen,
  setISOpen,
  root_data,
  setPaymentFailureReason,
  setIsCardSaveFailedOpen
) => {
  if (!validateForm(formData, setErrors)) {
    return;
  }
  setShowCardSaveModal(false);
  setLoading(true);
  let CardNumber;
  let ExpiryDate;
  localStorage.setItem("cardnumber", formData.cardNumber);
  localStorage.setItem("expir_date", formData.expiry);
  CardNumber = localStorage.getItem("cardnumber");
  ExpiryDate = localStorage.getItem("expir_date");

  if (CardNumber && ExpiryDate) {
    if (basysCustomerID.current) {
      try {
        const payload = {
          customer_id: basysCustomerID.current,
          number: CardNumber.replace(/-/g, ""),
          expiration_date: ExpiryDate.replace(/\//g, ""),
          pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
          IsTesting: isBasysTesting,
          guid: guid,
          corp_id: corpid,
          action_type: fromPayment ? `Basys save card from payment` : `Basys save card`
        };

        const { data } = await axios.post(
          `${API_BASE_URL}/save-vault-customer`,
          payload,
          {
            headers: {
              Authorization: basysApiToken,
              "Content-Type": "application/json",
            },
          }
        );

        if(data?.status !== "success") {
          setPaymentFailureReason(JSON.stringify(data));
          if(fromPayment) {
            setFailedIsOpen(true);
          } else {
            setIsCardSaveFailedOpen(true);
          }
          throw new Error(`Failed to save card`);
        }

        if (data?.status === "success" && !fromPayment) {
          let cardInfo = {};
          if (
            data?.data?.data?.customer?.payments?.cards?.length &&
            data?.data?.data?.customer?.payments?.cards?.length > 0
          ) {
            cardInfo =
              data?.data?.data?.customer?.payments?.cards[
                data?.data?.data?.customer?.payments?.cards.length - 1
              ];
          }

          await basysSaveCardPutRequest(
            accName,
            data?.data?.id,
            {
              CcType: cardInfo?.card_type,
              First4Num: cardInfo?.masked_number?.substring(0, 4),
              Last4Num: cardInfo?.masked_number?.slice(-4),
              ExpYear: cardInfo?.expiration_date?.substring(3),
              ExpMonth: cardInfo?.expiration_date?.substring(0, 2),
            },
            sendrespons,
            endpoint,
            guid,
            corpid,
            token
          );
          setIsCardSaveOpen(true);
          setCardHasBeenSaved(true);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    } else {
      try {
        // Check if customer account exists with the company name
        const companyName = basysAccountInfo?.AccountName;
        const response = await axios.get(
          `${API_BASE_URL}/get-vault-customer?action_type=IsCompanyRegisteredGetCall&pg_token=uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo&IsTesting=${isBasysTesting}&customer_id=${companyName.replace(
            /[@#$%^&*()!~']/g,
            " "
          )}`,
          {
            headers: {
              Authorization: basysApiToken,
              "Content-Type": "application/json",
            },
          }
        );

        let customerData;

        customerData = {
          id_format: "xid_type_last4",
          description: basysAccountInfo?.AccountName,
          flags: ["surcharge_exempt"],
          default_payment: {
            card: {
              number: CardNumber.replace(/-/g, ""),
              expiration_date: ExpiryDate.replace(/\//g, ""),
            },
          },
          pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
          IsTesting: isBasysTesting,
        };

        if (!response || response.data?.status !== "success") {
          // Account does not exist
          customerData.id = companyName.replace(/[@#$%^&*()!~']/g, " ");
        }
        customerData.default_billing_address = basysBillingAddrInfo;
        const { data } = await axios.post(
          `${API_BASE_URL}/api-authenticator-vault-customer`,
          customerData,
          {
            headers: {
              Authorization: basysApiToken,
              "Content-Type": "application/json",
            },
          }
        );

        if(data?.status !== "success") {
          setPaymentFailureReason(JSON.stringify(data));
          if(fromPayment) {
            setFailedIsOpen(true);
          } else {
            setIsCardSaveFailedOpen(true);
          }
          throw new Error(`Failed to create customer`);
        }

        if (data?.status === "success" && fromPayment) {
          basysCustomerID.current = data?.data?.id;
          console.log(
            "check payment id**********************",
            data?.data?.data?.customer?.payments?.cards[0]?.id
          );
          handleBasysCheckout(
            accName,
            data?.data?.data?.customer?.payments?.cards[0]?.id,
            setLoading,
            invoiceItems,
            basysCustomerID,
            basysAccountInfo,
            paynow,
            isBasysTesting,
            basysBillingAddrInfo,
            formData,
            basysApiToken,
            selectedRows,
            sendrespons,
            endpoint,
            guid,
            corpid,
            token,
            setFailedIsOpen,
            setISOpen,
            root_data,
            setErrors,
            setPaymentFailureReason,
          );
        } else {
          let cardInfo = {};
          if (
            data?.data?.data?.customer?.payments?.cards?.length &&
            data?.data?.data?.customer?.payments?.cards?.length > 0
          ) {
            cardInfo =
              data?.data?.data?.customer?.payments?.cards[
                data?.data?.data?.customer?.payments?.cards.length - 1
              ];
          }
          await basysSaveCardPutRequest(
            accName,
            data?.data?.id,
            {
              CcType: cardInfo?.card_type,
              First4Num: cardInfo?.masked_number?.substring(0, 4),
              Last4Num: cardInfo?.masked_number?.slice(-4),
              ExpYear: cardInfo?.expiration_date?.substring(3),
              ExpMonth: cardInfo?.expiration_date?.substring(0, 2),
            },
            sendrespons,
            endpoint,
            guid,
            corpid,
            token
          );
          setLoading(false);
          setIsCardSaveOpen(true);
          setCardHasBeenSaved(true);
        }
      } catch (error) {
        setPaymentFailureReason("Failed to create customer");
        if(fromPayment) {
          setFailedIsOpen(true);
        } else {
          setIsCardSaveFailedOpen(true);
        }
        setLoading(false);
        setShowCardSaveModal(false);
        console.error("Error occurred while creating customer:", error);
        // Handle error
      }
    }
  }
};
