import * as React from 'react';


export default function SaveCard({onCancel, onSave, vaultTerms}) {

    const [isCheck, setIsCheck] = React.useState(false)
    return (

        <div id="popup">
          <div className="popup_discription">
            <h2>Payment Terms</h2>
            <p>{vaultTerms}</p>
            <div className="popup_check_terms_condition">
              <input type="checkbox" name="checkbox" id onChange={() => setIsCheck(!isCheck)}/><span>I accept the above Payment Terms</span>
            </div>
            <hr />
            <div className="popup_button">
              <button  onClick={onCancel}>Cancel</button>
              <button disabled={!isCheck} style={{backgroundColor: isCheck ? "green" : 'gray'}} onClick={(onSave)}>Save</button>
            </div>
          </div>
        </div>
      );
}
