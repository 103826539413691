import React, { useEffect, useState } from 'react';
import Logo from "../../assets/images/logo.png";
import Visadebitcard from "../../assets/images/Visadebitcard.jpg";
import MasterCard from "../../assets/images/master card.jpg";
import AmricanExpress from "../../assets/images/amrican_express.jpg";
import SimpleDialogDemo from "../../components/popup/Success";
import Failure from '../../assets/images/failure.png';
import Success from '../../assets/images/success.png';
import SaveDetails from '../../assets/images/save.png';
import Loader from '../../assets/images/loader.gif'
import axios from 'axios';
import { useLocation } from "react-router-dom";
import CustomPaymentForm from '../../components/popup/CustomPaymentForm';
import SaveCard from '../../components/popup/CardSave';
export default function Home() {

  const [savecard, setSaveCard] = useState(false);
  const [savecardflag, setCardFlag] = useState(false);
  // console.log('savecardflag', savecardflag)
  const Opensavecardpopup = () => {
    setSaveCard(true);
  };

  const Closesavecardpopup = () => {
    setSaveCard(false);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [guid, setGuid] = useState(searchParams.get("id"));
  const [corpid, setCorpid] = useState(searchParams.get("corpid"));
  const [successResponse, setSuccessResponse] = useState(null);
  const [apiticket, setApiTicker] = useState();
  const [CurrencyCode, setCurrencyCode] = useState();
  const [finalticket, setFinalTicket] = useState();
  const StoreCard = localStorage.getItem("storecard");

  useEffect(() => {
    // console.log("ID:", guid);
    // console.log("Corpid:", corpid);
  }, [guid, corpid]);

  let [isOpen, setISOpen] = useState(false)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [useMoneris, setUseMoneris] = useState(true);

  const [formData, setFormData] = useState({
    amount: '',
    first_name: '',
    last_name: '',
    email: '',
    cardHolderName: '',
    cardNumber: '',
    expiry: '',
    cvv: '',
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [updateitem, setUpdateItem] = useState(null);
  const [errors, setErrors] = useState({});
  const currentYear = new Date().getFullYear() % 100;
  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;

    // Apply formatting for credit card number
    if (name === 'cardNumber' && value.length <= 16) {
      // Remove non-digit characters
      updatedValue = value.replace(/\D/g, '');
      // Add hyphens after every 4 digits
      updatedValue = updatedValue.replace(/(\d{4})/g, '$1-');
      // Remove any trailing hyphen
      updatedValue = updatedValue.replace(/-$/, '');
    }

    if (name === 'expiry') {
      updatedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      updatedValue = updatedValue.slice(0, 5); // Limit length to 5 characters
      // Ensure month does not exceed 12
      const month = parseInt(updatedValue.slice(0, 2), 10);
      if (month > 12) {
        updatedValue = '12' + updatedValue.slice(2);
      }
      // Ensure year does not exceed 99
      let year = parseInt(updatedValue.slice(2), 10);
      const currentYear = new Date().getFullYear() % 100; // Get last two digits of the current year
      if (year > 99) {
        year = Math.min(year, currentYear + 1); // Limit year to current year or next year
        updatedValue = updatedValue.slice(0, 2) + year.toString().padStart(2, '0');
      }
      // Add slash after 2nd character if needed
      if (updatedValue.length > 2 && !updatedValue.includes('/')) {
        updatedValue = updatedValue.slice(0, 2) + '/' + updatedValue.slice(2);
      }
      // Ensure no additional numbers can be entered after the expiry date is complete
      if (updatedValue.length === 5) {
        const [mm, yy] = updatedValue.split('/');
        if (mm.length === 2 && yy.length === 2) {
          updatedValue = mm + '/' + yy;
        }
      }
    }

    // Limit input length for cvv
    if (name === 'cvv') {
      updatedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (updatedValue.length > 4) {
        updatedValue = updatedValue.substring(0, 4); // Limit length to 4 digits
      }
    }

    setFormData({ ...formData, [name]: updatedValue });
    setErrors({ ...errors, [name]: '' }); // Clear error when user types
  };


  const validateForm = (data) => {
    let errors = {};

    if (!data.first_name.trim()) {
      errors.first_name = 'First Name is required';
    }
    if (!data.last_name.trim()) {
      errors.last_name = 'Last Name is required';
    }

    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = 'Invalid email format';
    }

    if (!data.cardHolderName.trim()) {
      errors.cardHolderName = 'Cardholder name is required';
    }

    if (!data.cardNumber.trim()) {
      errors.cardNumber = 'Card number is required';
    } else if (!/^\d{4}-?\d{4}-?\d{4}-?\d{4}$/.test(data.cardNumber)) {
      errors.cardNumber = 'Invalid card number';
    }

    // Validate Expiry Date
    if (!data.expiry.trim()) {
      errors.expiry = 'Expiry date is required';
    } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(data.expiry)) {
      errors.expiry = 'Invalid expiry date format (MM/YY)';
    } else {
      const [month, year] = data.expiry.split('/');
      const currentYear = new Date().getFullYear() % 100; // Get last two digits of the current year
      const currentMonth = new Date().getMonth() + 1; // Month is zero-based, so add 1
      if (parseInt(year, 10) < currentYear || (parseInt(year, 10) === currentYear && parseInt(month, 10) < currentMonth)) {
        errors.expiry = 'Expiry date must be in the future';
      }
    }

    if (!data.cvv.trim()) {
      errors.cvv = 'CVV is required';
    } else if (!/^\d{3,4}$/.test(data.cvv)) {
      errors.cvv = 'Invalid CVV';
    }

    return errors;
  };

  // FetchData code start here....
  const [data, Setdata] = useState();
  const [sendrespons, setSendRespons] = useState();
  const [paynow, SetPaynow] = useState();
  const [itemtype, SetItemType] = useState();

  const fetchData = async () => {
    const token = '9jyQOE-bYmTkqL6W4tYwhScOTmaEUX5qufbwX_QtWTfUddZtN2q4JAbcd0T5-17EBacYjLIph-DXLPKHO3Pi5P9D040cSFZnksJ4Sfpgs-pYc9aSJ1Y_JNyp2kwUBDDKggTzi7AHknBK5Iwc984_3pa6cOBgYXfj_tNc1K-WTwbXxsRolIHiBh1i1OxILc8LMpH7khnngkBVEgCAbS9esBAPJhla9UONBJsF1MyIel_JwxVurtU7D2q_EpyhEet43NUf3wzo8i8Te3XFQvTyIVElwDdF9XGvtUc0a0jljDtVOAfZP10AxZADITdNwnzzCiPn1ATvpjEOJjftuAUgaRZqULhsGgx9OVSmLy5ooo56d57-zVeUDs4HaGcuLQPcA6thOfHBjcggH4bNBmVI04HGlsVjt3EYThAGOmMtJL7jkrLGda7Rd7ilVZ5pnGEdlZrdVl1WNCDL_7j11iooEw3C6aS2ywy6rJhFMDWTUWK-rRvyWIRRD_LZRSWaAieT';

    try {
      const response = await axios.get(`https://sdssymphony.ca:10880/api/paynow/Get?corpid=${corpid}&id=${guid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      });
      setSendRespons(response.data);
      setCurrencyCode(response.data.CurrencyCode);
      SetPaynow(response.data.ThePayNowOptions);
      // console.log('response.data.ThePayNowOptions', itemtype);
      SetItemType(response.data.ItemType);
      if (itemtype == 0) {
        Setdata(response.data.InvoiceItems);
      } else if (itemtype == 1) {
        Setdata(response.data.CiaByWeekItems);
      } else if (itemtype == 2) {
        Setdata(response.data.CiaByMonthItems);
      } else if (itemtype == 3) {
        Setdata(response.data.CiaPrepayItems);
      } else {
        console.log("itemtype not found!")
      }

      setLoading(false);
    } catch (error) {
      // console.error('Error fetching data:', error);
      setError(error.message);
      setLoading(false);
      // throw new Error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [itemtype]);

  const calculateTotal = () => {
    let total = 0;
    selectedRows.forEach(index => {
      const currentItem = data[index];
      if (currentItem.hasOwnProperty('EditedAmountDue')) {
        total += parseFloat(currentItem.EditedAmountDue);
      } else {
        total += parseFloat(currentItem.AmountDue / 100);
      }
    });
    return total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter(item => item !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
    setSelectAll(selectedRows.length + 1 === data.length);
  };

  // Handle select all checkbox change
  const handleSelectAllChange = () => {
    if (!selectAll) {
      const allIndices = data.map((_, index) => index);
      setSelectedRows(allIndices);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(!selectAll);
  };

  const handleAmountChange = (e, index) => {
    const value = e.target.value;
    const updatedData = [...data];
    // console.log('updatedData', updatedData);

    if (!isNaN(value) && value <= data[index].AmountDue / 100) {
      updatedData[index].EditedAmountDue = value;
      Setdata(updatedData);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year

    // Pad day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedMonth}/${formattedDay}/${year}`;
  }



  // Payment gateway code Start here...
  let [ticket, setTicket] = useState("");
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

  const API_URL = 'https://sds-pg-proxy.codepaperclient.com/api/api-authenticator';

  React.useEffect(() => {

    const orderNo = Math.floor(Math.random() * 1000000000).toString();
    const requestData = {

      "store_id": "store1",
      "api_token": "yesguy1",
      "checkout_id": "chktP5YTCtore1",
      "txn_total": "10.22",
      "environment": "qa",
      "action": "preload",
      "language": "en",
      "order_no": orderNo,
      "token": [
        {
          "data_key": "0noDsBMCjvwrTCvmplg9f6MZ2",
          "issuer_id": "issuerid1"
        },

      ],
      "ask_cvv": "Y",
      "cust_id": "Testing Customer ID",
      "dynamic_descriptor": "dyndesc",
      "cart": {
        "items": [
          {
            "url": "https:\/\/example.com\/examples\/item1.jpg",
            "description": "One item",
            "product_code": "one_item",
            "unit_cost": "100.00",
            "quantity": "1"
          },
          {
            "url": "https:\/\/example.com\/examples\/item2.jpg",
            "description": "Two item",
            "product_code": "two_item",
            "unit_cost": "200.00",
            "quantity": "1"
          },
          {
            "url": "https:\/\/example.com\/examples\/item3.jpg",
            "description": "Three item",
            "product_code": "three_item",
            "unit_cost": "100.00",
            "quantity": "1"
          }
        ],
        "subtotal": "10.00",
        "tax": {
          "amount": "52.00",
          "description": "HST Taxes:12",
          "rate": "13.00"
        }
      },
      "pg_token": "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo"
    }
    // Define an asynchronous function to fetch the data
    async function fetchData() {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Add any additional headers here
            // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
          },
          body: JSON.stringify(requestData),
        };

        const response = await fetch(`${API_URL}`, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        // console.log("check SUCCESS--<", jsonData.response.ticket)
        setTicket(jsonData.response.ticket);
        // console.log('jsonData.response.ticket', jsonData.response.ticket)
      } catch (error) {
        // console.log("check error--<", error)
        setError(error.message);
      }
    }

    // Call the async function to fetch the data
    fetchData();
  }, []);

  function handelCancel() {
    setISOpen(false);
    window.location.href = `/?id=${guid}`;
  }

  // Basys checkout code start here.....
  const [basysApiResponse, setBasysApiResponse] = useState(null);

  const handleBasysCheckout = async (e) => {
    e.preventDefault();
    const data = {
      "type": "sale",
      "amount": 100,
      "tax_amount": 100,
      "shipping_amount": 100,
      "currency": "USD",
      "description": "test transaction",
      "order_id": "1222",
      "po_number": "somePONumber",
      "ip_address": "192.168.3.228",
      "email_receipt": false,
      "email_address": "devloper@mailinator.com",
      "create_vault_record": true,
      "payment_method": {
        "card": {
          "entry_type": "keyed",
          "number": formData.cardNumber.replace(/-/g, ''),
          "expiration_date": formData.expiry,
          "cvc": formData.cvv
        }

      },
      "pg_token": "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo"
    }

    try {
      const response = await axios.post('https://sds-pg-proxy.codepaperclient.com/api/api-authenticator-basysiqpro', data, {
        headers: {
          Authorization: "api_1vd1WselC6UI2IikShePqwBovS9",
          "Content-Type": "application/json"
        }
      });
      setBasysApiResponse(response.data);
      // console.log('response.data', response.data);
      if (response.data.msg === 'success') {
        await createCustomer();
      }
      setISOpen(true);
      // StoreCard
    } catch (error) {
      // Handle error
      console.error('Error occurred while making API call:', error);
    }
  };

  // Basys checkout code end here......

  // Basys Save card details code start here...
  let CardNumber;
  let ExpiryDate;
  const [Response, setResponse] = useState(null);
  // console.log('basysApiResponse', basysApiResponse);

  localStorage.setItem("cardnumber", formData.cardNumber);
  localStorage.setItem("expir_date", formData.expiry);

  const createCustomer = async () => {

    CardNumber = localStorage.getItem("cardnumber");
    ExpiryDate = localStorage.getItem("expir_date");

    if (CardNumber && ExpiryDate) {
      try {
        const customerData =
        {
          "id_format": "xid_type_last4",
          "description": "test description",
          "flags": ["surcharge_exempt"],
          "default_payment": {
            "card": {
              "number": CardNumber.replace(/-/g, ''),
              "expiration_date": ExpiryDate.replace(/\//g, '')
            }
          },
          "default_billing_address": {
            "first_name": "kuldip",
            "last_name": "shah",
            "company": "Some Business",
            "line_1": "123 Some St",
            "line_2": "STE 1",
            "city": "Some Town",
            "state": "IL",
            "postal_code": "60187",
            "country": "US",
            "email": "user@somesite.com",
            "phone": "5555555555",
            "fax": "555555555"
          },
          "default_shipping_address": {
            "first_name": "John",
            "last_name": "Smith",
            "company": "Some Business",
            "line_1": "123 Some St",
            "line_2": "STE 1",
            "city": "Some Town",
            "state": "IL",
            "postal_code": "60187",
            "country": "US",
            "email": "user@somesite.com",
            "phone": "5555555555",
            "fax": "555555555"
          },
          "pg_token": "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo"
        }




        const apiKey = "api_1vd1WselC6UI2IikShePqwBovS9"; // Replace with your BasysIQ Pro API key

        const response = await axios.post('https://sds-pg-proxy.codepaperclient.com/api/api-authenticator-vault-customer', customerData, {
          headers: {
            Authorization: apiKey,
            "Content-Type": "application/json"
          }
        });

        setResponse(response.data);
        console.log('after payment success', response.data)
      } catch (error) {
        console.error('Error occurred while creating customer:', error);
        // Handle error
      }
    }

  };

  // Basys Save card details code end here....


  // start moneris payment process api code here....

  const handleClickOpen = async (e) => {

    e.preventDefault();
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length === 0) {
      e.preventDefault();
      try {
        const response = await fetch('https://pg-proxy.iappsolution.tech/api/api-authenticator-request', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ticket: ticket,
            action: 'process_transaction',
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            cardholder: formData.cardHolderName,
            pan: formData.cardNumber.replace(/-/g, ''),
            expiry_date: formData.expiry.replace(/\//g, ''),
            cvv: formData.cvv,
            currency_code: 'CAD',
            wallet_details: {},
            gift_details: {},
            card_data_key: 'new',
            pg_token: 'uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo'
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        // console.log(responseData.response.success);
        setFinalTicket(responseData.response.ticket);
        if (responseData.response.success === "true") {
          paymentReceipt();
        }

        // setISOpen(true);
        // Handle response as needed, e.g., show success message
      } catch (error) {
        console.error('Error:', error);
        // Handle error, e.g., show error message
      }
      // console.log('Form submitted successfully!');
    } else {
      setErrors(formErrors);
    }
  };

  // end moneris payment process api code here....

  // moneris payment receipt api code start here....

  async function paymentReceipt() {
    const TicketID = finalticket;
    console.log('apiticket', TicketID);
    if (TicketID) {

      try {
        // console.log('apiticket', TicketID)
        const apiResponse = await axios.post('https://pg-proxy.iappsolution.tech/api/api-authenticator', {
          store_id: "store1",
          api_token: "yesguy1",
          checkout_id: "chktP5YTCtore1",
          ticket: TicketID,
          environment: "qa",
          action: "receipt",
          pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo"
        });

        if (apiResponse.data.response && apiResponse.data.response.success === "true") {
          // If successful, set isOpen to true
          // console.log('apiResponse.data.response', apiResponse.data.response)
          setSuccessResponse(apiResponse.data.response);
          setISOpen(true);
        } else {
          console.error('API call failed:', apiResponse.data.response.error.message);
          // Optionally, you can show an error message to the user
        }
      } catch (error) {
        console.error('Error occurred while calling API:', error);
        // Optionally, you can show an error message to the user
      }
    }

  }
  // moneris payment receipt api code end here...

  // Call SDS PUT API code start here........
console.log('sendrespons', sendrespons);


  const handleButtonClick = async () => {
    try {
      const url = 'https://sdssymphony.ca:10880/api/paynow/Put?id=37F30EE8-D4C5-47D4-A68F-208B9B858C4E';
      const data = [...sendrespons]
      console.log(data);
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      console.log('API Response:', responseData);
      // Handle the response here as needed
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error here
    }
  };

  //  Call SDS PUT API code end here........

  return (
    <>

      <div className="dashboard">
        {/* Dashboard head Start */}
        <div className="dashboard_head">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="contect">
            {/* <p>Need Help? {Call 902-425-1225 ext. 1290}</p> */}
            {paynow && paynow.ShowTopBanner == true && <p>Need Help? {paynow.HelpCenterPhone}</p> || ""}
          </div>
        </div>
        {/* Dashboard head end */}



        {/* Dashbord Body start */}
        <div className="dashboard_body">
          <div className="paymemt_history">
            <div className="paymemt_history_heading">
              <div className='first-column-header-title'>
                <h2>Invoice Details</h2>
                <div className='toggle-switch-parent-container'>
                  <span className='toggle-switch-label'>Use Basys</span>
                  <span>
                    <label class="toggle-switch-container">
                      <input type="checkbox" />
                      <span class="toggle-switch-slider round" onClick={() => setUseMoneris(!useMoneris)}></span>
                    </label>
                  </span>
                  <div className="subtotale">
                    <strong>  <label>Subtotal ({selectedRows && selectedRows.length || 0} Invoices): </label>
                      <span>{selectedRows.length > 0 && <span>{calculateTotal()}</span> || "$00.00"}</span></strong>
                  </div>
                </div>
                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore harum sequi fugiat. </p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> */}
                {paynow && paynow.ShowInvDetailsDescription == true && <p>{paynow.InvDetailsDescription}</p> || ""}
              </div>

            </div>

            <SimpleDialogDemo open={isOpen} handelCancel={handelCancel} ButtonText={"Proceed"} image={Success} alt={"successlogo"} title={"Payment Successfully"} decs={"The payment has been successfully sent,reception will receive receipt in less than 1 minute"} ButtonHandel={handelCancel} color={"#048547"} />

            {/* <SimpleDialogDemo open={isOpen} handelCancel={handelCancel} ButtonText={"Re-sent"} image={Failure} alt={"failurelogo"} title={"Payment not successfull"} decs={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."} ButtonHandel={handelCancel} color={"#FF4B4B"} />

            <SimpleDialogDemo open={isOpen} handelCancel={handelCancel} ButtonText={"Proceed"} image={SaveDetails} alt={"save details"} title={"Credit Card Save"} decs={"Lorem Ipsum is simply dummy text of the printing and typesetting industry."} ButtonHandel={handelCancel} color={"#2D4691"} /> */}

            <div className="payment_history_table">
              <table>
                {/* table heading start */}
                <tbody>
                  <tr>
                    {/* <th>No</th> */}
                    <th>  <button onClick={handleSelectAllChange}>{selectAll ? 'Deselect All' : 'Select All'}</button></th>
                    <th>Station</th>
                    <th>Contract</th>
                    <th>Invoice</th>
                    <th>Invoice Date</th>
                    <th className='textright amout_width'>Invoice Amount</th>
                    <th className='textright'>Balance</th>
                    <th>Amount To Pay</th>
                  </tr>
                  {/* table heading End */}
                  {/* table Discription start */}
                  {/* {Array.isArray(data) && data.map(item => ( */}
                  {/* {loading && <Loader />} */}
                  <div className="loader-container">
                    {loading && (
                      <>
                        <div id="loader"></div>
                        <p>Loading...</p>
                      </>
                    )}
                  </div>
                  <div className="loader-container">
                    {!loading && !data && <p>Data Not Found</p>}
                  </div>
                  {/* {error && <p>Error: {error}</p>} */}
                  {data && data.map((item, i) => {
                    // { console.log(item) }
                    // const DueAmount = item.AmountDue;
                    return (
                      <tr key={i}>
                        <td><input type="checkbox" onChange={() => handleCheckboxChange(i)} checked={selectedRows.includes(i)} /></td>
                        <td>{item.SummaryInvoiceNumber > 0 && "Multi" || item.SummaryInvoiceNumber == 0 && item.StationName}</td> {/* This line might need to be modified based on the structure of your data */}
                        <td>{item.SummaryInvoiceNumber > 0 && "Multi" || item.SummaryInvoiceNumber == 0 && item.ContractNumber}</td>
                        <td>{item.SummaryInvoiceNumber > 0 && item.SummaryInvoiceNumber || item.SummaryInvoiceNumber == 0 && item.InvoiceNum}</td>
                        <td>{formatDate(item.InvoiceDate)}</td>
                        <td className='textright'>{(item.InvoiceAmount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                        <td className='textright'>{(item.AmountDue / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                        <td className='dollar'>
                          <div>$
                            <input className='editamount textright'
                              type="number"
                              value={(item.EditedAmountDue !== undefined ? (item.EditedAmountDue) : (item.AmountDue / 100).toFixed(2))}
                              onChange={(e) => handleAmountChange(e, i)}
                              step="0.01"
                            /></div>
                        </td>
                      </tr>

                    );
                  })}

                  {/* table Discription End */}

                </tbody></table>

            </div>

            <div className="payment_information_accounting_office">
              <div>
                {paynow && paynow.ShowBottomBanner == true && <h2>{paynow.Title}</h2> || ""}

                {paynow && paynow.ShowBottomBanner == true && <p>Phone: <a style={{ color: "var(--font-gray-color)", textDecoration: "none" }} href="tel:+1-416-987-3456">{paynow.Phone} </a></p>}
                {paynow && paynow.ShowBottomBanner == true && <p>Email: <a style={{ color: "var(--font-gray-color)", textDecoration: "none" }} href="mailto:accounting@somecompany.com">{paynow.Email} </a></p>}
                {paynow && paynow.ShowBottomBanner == true && <p>Address: {paynow.Address} {paynow.City}, {paynow.Prov}, {paynow.Postal}</p>}
                {/* <p>90 Lovett Lake Ctr. Suite 101 Halifax, Ns B3S 0H6</p> */}
              </div>
              <div className="card_vault">
                <div>
                  <h3>Credit Card Vault</h3>
                  <p>Securely store your credit card, for automatic <br />monthly payments of future invoices</p>
                </div>
                <div>
                  {!formData.cardHolderName || !formData.first_name || !formData.last_name || !formData.email || !formData.cardNumber || !formData.cvv || !formData.expiry ? (
                    <button disabled style={{ backgroundColor: "gray" }}>Store card</button>
                  ) : (
                    <button onClick={Opensavecardpopup}>Store card</button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* card details */}
          {!useMoneris ?
            <CustomPaymentForm
              handleChange={handleChange}
              formData={formData}
              handleSubmit={handleBasysCheckout}
              errors={errors}
            /> :
            <div className="payment_information">
              <div className="payment_information_heading">
                <div className="payment_information_heading_details">
                  <h2>Payment Information</h2>
                  <div className="paymemt_card">
                    <img src={Visadebitcard} alt="Visadebitcard" />
                    <img src={MasterCard} alt="MasterCard" />
                    <img src={AmricanExpress} alt="AmricanExpress" />
                  </div>
                </div>
              </div>
              <form>
                <div className="formdetails" id="formdetails">

                  <label>First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder="Enter First Name"
                  />
                  {errors.first_name && <p className="error">{errors.first_name}</p>}

                  <label>Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder="Enter Last Name"
                  />
                  {errors.last_name && <p className="error">{errors.last_name}</p>}


                  <label> Email ID</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter Email ID"
                  />
                  {errors.email && <p className="error">{errors.email}</p>}


                  <div className="card_info_tag">
                    <hr />
                    <p>Card Information</p>
                  </div>


                  <label>Cardholder Name</label>
                  <input
                    autocomplete="cc-name"
                    type="text"
                    name="cardHolderName"
                    value={formData.cardHolderName}
                    onChange={handleChange}
                    placeholder="Enter cardholder Name"
                  />
                  {errors.cardHolderName && <p className="error">{errors.cardHolderName}</p>}

                  <label>Card Number</label>
                  <input
                    autocomplete="cc-number"
                    type='tel'
                    name="cardNumber"
                    pattern='[\d| ]{16,22}'
                    maxLength='19'
                    value={formData.cardNumber}
                    onChange={handleChange}
                    placeholder="0000-0000-0000-0000"
                  />
                  {errors.cardNumber && <p className="error">{errors.cardNumber}</p>}


                  <div className="card_cvv_expiry">
                    <div className="card_expiry">
                      <label>Expiry</label>
                      <input
                        autocomplete="cc-exp"
                        type="tel"
                        name="expiry"
                        value={formData.expiry}
                        onChange={handleChange}
                        placeholder="MM/YY"
                      />
                      {errors.expiry && <p className="error">{errors.expiry}</p>}
                    </div>
                    <div className="card_cvv">
                      <label>Cvv</label>
                      <input
                        autocomplete="cc-csc"
                        type="tel"
                        name="cvv"
                        value={formData.cvv}
                        onChange={handleChange}
                        placeholder="CVV"
                      />
                      {errors.cvv && <p className="error">{errors.cvv}</p>}
                    </div>
                  </div>
                  <button type="submit" onClick={(e) => { handleClickOpen(e) }}> Process Payment</button>
                  {/* <p id="terms"><b>©2024</b> Maritime Broadcast Systems</p> */}

                </div>
              </form>
            </div>
          }
          {/* {CurrencyCode == "!CAD" ?
            <CustomPaymentForm
              handleChange={handleChange}
              formData={formData}
              handleSubmit={handleBasysCheckout}
              errors={errors}
            /> :
            <div className="payment_information">
              <h2>Payment Information</h2>
              <div id="monerisCheckout" />
            </div>
          } */}
        </div>
        {/* Dashbard Body End */}
        {savecard && <SaveCard onCancel={() => setSaveCard(false)} onSave={() => { setSaveCard(false); setCardFlag(true); }} />}
      </div>
    </>
  );
}
