import axios from "axios";
import {
  API_BASE_URL,
  paymentInitialTicketPayload,
  paymentReceiptPayload,
  saveCardInitialTicketPayload,
  saveCardReceiptPayload,
} from "../../config/constants";
import { calculateTotalForInvoices, storeLog, validateForm } from "../../utils";

const getInitialTicketForSaveCard = async (
  accName,
  credentials,
  formData,
  paynow,
  currencyCode,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  try {
    const { data, status } = await axios.post(
      `${API_BASE_URL}/api-authenticator`,
      {
        ...saveCardInitialTicketPayload,
        ...credentials,
        cust_id: accName.replace(/[@#$%^&*()!~']/g, " "),
        guid: guid,
        corp_id: corpid,
        action_type: "Save Card InitialTicket"
      }
    );

    if (data?.response?.ticket) {
      await saveCardHandleInitialTicket(
        accName,
        data?.response?.ticket,
        credentials,
        formData,
        paynow,
        currencyCode,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        monerisSaveCardOptions
      );
    }
  } catch (err) {
    console.log(err);
  }
};

const saveCardHandleInitialTicket = async (
  accName,
  ticket,
  credentials,
  formData,
  paynow,
  currencyCode,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  try {
    let payload = {
      ticket: ticket,
      action: "process_transaction",
      cardholder: formData.cardHolderName,
      pan: formData.cardNumber.replace(/-/g, ""),
      expiry_date: formData.expiry.replace(/\//g, ""),
      cvv: formData.cvv,
      email: paynow?.Email,
      currency_code: currencyCode,
      card_data_key: "new",
      pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
      guid: guid,
      corp_id: corpid,
      action_type: "Save Card Process transaction"

    };

    if (monerisSaveCardOptions?.FirstNameSet === "true") {
      payload.first_name = formData.first_name;
    }

    if (monerisSaveCardOptions?.LastNameSet === "true") {
      payload.last_name = formData.last_name;
    }

    if (monerisSaveCardOptions?.PhoneNumSet === "true") {
      payload.phone = formData.phone;
    }
    const { data } = await axios.post(
      `${API_BASE_URL}/api-authenticator-request`,
      payload
    );

    setLoading(false);

    if (
      data &&
      data.response &&
      data.response.success === "true" &&
      data.response.ticket
    ) {
      await saveCardHandleFinalTicket(
        accName,
        data.response.ticket,
        credentials,
        formData,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        monerisSaveCardOptions
      );
    } else {
      console.error("Response data is invalid or incomplete:", data);
    }
  } catch (error) {
    setLoading(false);
    console.error("Error:", error);
  }
};

const saveCardHandleFinalTicket = async (
  accName,
  ticket,
  credentials,
  formData,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  try {
    const { data, status } = await axios.post(
      `${API_BASE_URL}/api-authenticator`,
      {
        ...saveCardReceiptPayload,
        ...credentials,
        ticket,
        guid: guid,
        corp_id: corpid,
        action_type: "Save Card Final Receipt"
      }
    );

    const theMonerisCC = {
      CcToken: data?.response?.receipt?.cc?.tokenize?.datakey,
      CcType: data?.response?.receipt?.cc?.card_type,
      First4Num: data?.response?.receipt?.cc?.tokenize?.first4last4.substring(
        0,
        4
      ),
      Last4Num: data?.response?.receipt?.cc?.tokenize?.first4last4.slice(-4),
      ExpYear: data?.response?.receipt?.cc?.expiry_date?.substring(2),
      ExpMonth: data?.response?.receipt?.cc?.expiry_date?.substring(0, 2),
      StoreID: credentials.store_id,
    };

    await saveCardPutRequest(
      accName,
      theMonerisCC,
      formData,
      endpoint,
      guid,
      corpid,
      token,
      sendrespons,
      monerisSaveCardOptions
    );
  } catch (err) {
    console.log(err);
  }
};

const saveCardPutRequest = async (
  accName,
  theMonerisCC,
  formData,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  monerisSaveCardOptions
) => {
  let payload = sendrespons;
  try {
    payload.IsCcSaved = true;
    payload.TheMonerisCC = theMonerisCC;
    payload.guid = guid;
    payload.corp_id = corpid;

    if (monerisSaveCardOptions?.BillingAddressSet === "true") {
      payload.PayerAccount = {
        ...payload.PayerAccount,
        Address: {
          Line1: formData.addressLine1,
          Line2: formData.addressLine2,
          City: formData.city,
          Country: formData.country,
          PostalCode: formData.postalCode,
          Province: formData.province,
        },
      };
    }

    const { data, status } = await axios.put(
      `${endpoint}/Put?id=${guid}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Origin_Custom: endpoint,
        },
      }
    );

    storeLog(
      "info",
      `Save Card PUT request: "${JSON.stringify(payload)}"`,
      status,
      guid,
      corpid,
      "PUT Call - Save Card",
      `PUT Call - Save Card for moneris for ${accName}`,
      "Sucess"
    );
  } catch (err) {
    storeLog(
      "error",
      `Save Card PUT request: "${JSON.stringify(payload)}"`,
      500,
      guid,
      corpid,
      "PUT Call - Save Card",
      `PUT Call -Save Card for moneris for ${accName}`,
      "Failure"
    );
    console.log(err);
  }
};

export const handleSaveCard = async (
  accName,
  formData,
  paynow,
  currencyCode,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  groupedInvoices,
  sendrespons,
  monerisSaveCardOptions,
  setShowCardSaveModal,
  setCardFlag,
  setIsCardSaveOpen
) => {
  setLoading(true);
  setShowCardSaveModal(false);
  setCardFlag(true);
  groupedInvoices.forEach(async (groupedInvoiceItem, index) => {
    await getInitialTicketForSaveCard(
      accName,
      {
        store_id: groupedInvoiceItem.MonerisAPICredential.MonerisStoreID,
        api_token: groupedInvoiceItem.MonerisAPICredential.APIToken,
        checkout_id:
          groupedInvoiceItem.MonerisAPICredential.MonerisCardChechoutID,
      },
      formData,
      paynow,
      currencyCode,
      setLoading,
      endpoint,
      guid,
      corpid,
      token,
      sendrespons,
      monerisSaveCardOptions
    );

    if (index === groupedInvoices.length - 1) {
      setLoading(false);
      setIsCardSaveOpen(true);
    }
  });
};

const getInitialTicketForPayment = async (
  accName,
  credentials,
  invoices,
  formData,
  paynow,
  currencyCode,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  data,
  setPaymentFailureReason
) => {
  const total = calculateTotalForInvoices(invoices, data, selectedRows);

  // Convert total to a formatted string with two decimal places
  const formattedTotal = (total / 100).toFixed(2);

  try {
    const { data, status } = await axios.post(
      `${API_BASE_URL}/api-authenticator`,
      {
        ...paymentInitialTicketPayload,
        ...credentials,
        txn_total: formattedTotal,
        cust_id: accName.replace(/[@#$%^&*()!~']/g, " "),
        corp_id: corpid,
        guid: guid,
        action_type: "Payment Handle InitialTicket"
      }
    );

    if(data?.response?.success !== "true") {
      setPaymentFailureReason(JSON.stringify(data?.response?.error));
      throw new Error(`Failed to get the initial payment ticket`);
    }

    if (data?.response?.ticket) {
      await paymentHandleInitialTicket(
        accName,
        credentials,
        data?.response?.ticket,
        invoices,
        formData,
        paynow,
        currencyCode,
        monerisPaymentOptions,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        selectedRows,
        invoiceItems,
        invoicesListForPut,
        setPaymentFailureReason
      );
    }
  } catch (err) {
    console.log(err);
    throw new Error("Failed to get initial payment ticket");
  }
};

const paymentHandleInitialTicket = async (
  accName,
  credentials,
  ticket,
  invoices,
  formData,
  paynow,
  currencyCode,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  setPaymentFailureReason
) => {
  try {
    let payload = {
      ticket: ticket,
      action: "process_transaction",
      cardholder: formData.cardHolderName,
      pan: formData.cardNumber.replace(/-/g, ""),
      expiry_date: formData.expiry.replace(/\//g, ""),
      cvv: formData.cvv,
      email: paynow?.Email,
      currency_code: currencyCode,
      card_data_key: "new",
      pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
      guid: guid,
      corp_id: corpid,
      action_type: "Payment Process transaction"
    };

    if (monerisPaymentOptions?.FirstNameSet === "true") {
      payload.first_name = formData.first_name;
    }

    if (monerisPaymentOptions?.LastNameSet === "true") {
      payload.last_name = formData.last_name;
    }

    if (monerisPaymentOptions?.PhoneNumSet === "true") {
      payload.phone = formData.phone;
    }

    const { data, status } = await axios.post(
      `${API_BASE_URL}/api-authenticator-request`,
      payload
    );

    if(data?.response?.success !== "true") {
      setPaymentFailureReason(JSON.stringify(data?.response?.error));
      throw new Error(`Failed to handle the initial payment ticket`);
    }

    if (
      data &&
      data.response &&
      data.response.success === "true" &&
      data.response.ticket
    ) {
      await paymentHandleFinalTicket(
        accName,
        credentials,
        data.response.ticket,
        invoices,
        formData,
        monerisPaymentOptions,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        selectedRows,
        invoiceItems,
        invoicesListForPut,
        setPaymentFailureReason
      );
    } else {
      console.error("Response data is invalid or incomplete:", data);
      throw new Error("Failed toinitialise payment");
    }
  } catch (error) {
    setLoading(false);
    console.error("Error:", error);
    throw new Error("Failed to initialize payment");
  }
};

const paymentHandleFinalTicket = async (
  accName,
  credentials,
  ticket,
  invoices,
  formData,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  setPaymentFailureReason
) => {
  try {
    const { data, status } = await axios.post(
      `${API_BASE_URL}/api-authenticator`,
      {
        ...paymentReceiptPayload,
        ...credentials,
        ticket,
        corp_id: corpid,
        guid: guid,
        action_type: "Payment Handle Final Ticket",
      }
    );

    if(data?.response?.success !== "true") {
      setPaymentFailureReason(JSON.stringify(data?.response?.error));
      throw new Error(`Failed to handle the final payment ticket`);
    }

    const responseCode = data?.response?.receipt?.cc?.response_code;

    if(!responseCode || parseInt(responseCode) < 0 || parseInt(responseCode) > 29) {
      setPaymentFailureReason(`${data?.response?.receipt?.cc?.response_code}: ${data?.response?.receipt?.cc?.message}`);
      throw new Error(`Failed to handle the final payment ticket`);
    }

    if (data?.response) {
      await accumulateInvoicesForPut(
        accName,
        data.response,
        invoices,
        formData,
        monerisPaymentOptions,
        setLoading,
        endpoint,
        guid,
        corpid,
        token,
        sendrespons,
        selectedRows,
        invoiceItems,
        invoicesListForPut,
        setPaymentFailureReason
      );
    } else {
      throw new Error("Failed to finalize payment");
    }
  } catch (err) {
    console.log(err);
    setLoading(false);
    throw new Error("Failed to finalize payment");
  }
};

const accumulateInvoicesForPut = async (
  accName,
  dataSuccessResponse,
  invoices,
  formData,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  selectedRows,
  invoiceItems,
  invoicesListForPut,
  setPaymentFailureReason
) => {
  let invoiceItemsTmp = [];
  let addedInvoices = [];

  let checkoutId = invoices[0].MonerisAPICredential.MonerisPaymentChechoutID;

  try {
    if (selectedRows.length === 0) {
      invoiceItemsTmp = invoices;
    } else {
      for (let i = 0; i < invoices.length; i++) {
        if (selectedRows.includes(invoices[i].index)) {
          const paymentAmount = parseInt(
            invoices[i].PaymentResponse.PaymentAmount.replace(/[,]/g, "")
          );
          const updatedPaymentResponse = {
            TransactionDate:
              dataSuccessResponse.receipt.cc.transaction_date_time,
            TransactionID: dataSuccessResponse.receipt.cc.transaction_no,
            ReferenceNumber: dataSuccessResponse.receipt.cc.reference_no,
            PaymentStatus: 1,
            PaymentAmount:
              paymentAmount !== 0 ? paymentAmount : invoices[i]?.AmountDue,
            ResponseCode: dataSuccessResponse.receipt.cc.response_code,
            ResponseMessage: "successfully",
          };
          invoices[i].PaymentResponse = updatedPaymentResponse;
          invoiceItemsTmp.push(invoices[i]);
        }
      }

      addedInvoices = invoiceItemsTmp.map((item) => item.InvoiceNum);

      for (let i = 0; i < invoiceItems.length; i++) {
        if (
          !addedInvoices.includes(invoiceItems[i].InvoiceNum) &&
          invoiceItems[i].MonerisAPICredential.MonerisPaymentChechoutID ===
          checkoutId
        ) {
          invoiceItemsTmp.push(invoiceItems[i]);
        }
      }
    }

    await paymentPutRequest(
      accName,
      [...invoicesListForPut.current, ...invoiceItemsTmp],
      {
        CcType: dataSuccessResponse.receipt.cc.card_type,
        Last4Num: dataSuccessResponse.receipt.cc.first6last4?.slice(-4),
      },
      formData,
      monerisPaymentOptions,
      setLoading,
      endpoint,
      guid,
      corpid,
      token,
      sendrespons,
      setPaymentFailureReason
    );
  } catch (err) {
    console.log(err);
    setLoading(false);
    throw new Error("Failed payment PUT request");
  }
};

const paymentPutRequest = async (
  accName,
  invoices,
  monerisCardInfo,
  formData,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  sendrespons,
  setPaymentFailureReason
) => {
  let data = sendrespons;
  try {
    data = {
      ...data,
      InvoiceItems: invoices,
      TheMonerisCC: monerisCardInfo,
      IsCcSaved: false,
      corp_id: corpid,
      guid: guid,
    };

    if (monerisPaymentOptions?.BillingAddressSet === "true") {
      data.PayerAccount = {
        ...data.PayerAccount,
        Address: {
          Line1: formData.addressLine1,
          Line2: formData.addressLine2,
          City: formData.city,
          Country: formData.country,
          PostalCode: formData.postalCode,
          Province: formData.province,
        },
      };
    }

    const response = await axios.put(`${endpoint}/Put?id=${guid}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        Origin_Custom: endpoint,
      },
    });

    storeLog(
      "info",
      `Payment PUT request: "${JSON.stringify(data)}"`,
      response.status,
      guid,
      corpid,
      "PUT Call - Payment",
      `PUT Call - Payment in moneris for ${accName}`,
      "Success"
    );
  } catch (error) {
    setPaymentFailureReason("PUT call failed");
    storeLog(
      "error",
      `Payment PUT request: "${JSON.stringify(data)}"`,
      500,
      guid,
      corpid,
      "PUT Call - Payment",
      `PUT Call - Payment in moneris for ${accName}`,
      "Failure"
    );
    setLoading(false);
    console.error("Error fetching data:", error);
    throw new Error("Failed payment PUT request");
  }
};

export const handlePayment = async (
  e,
  accName,
  formData,
  paynow,
  currencyCode,
  monerisPaymentOptions,
  setLoading,
  endpoint,
  guid,
  corpid,
  token,
  groupedInvoices,
  setFailedIsOpen,
  setISOpen,
  selectedRows,
  sendrespons,
  invoiceItems,
  invoicesListForPut,
  data,
  setErrors,
  setPaymentFailureReason
) => {
  e.preventDefault();

  if (!validateForm(formData, setErrors)) {
    return;
  }

  setLoading(true);

  let validGroupsCount = 0;
  let modifiedGroup = [];

  groupedInvoices.forEach(async (groupedInvoiceItem, index) => {
    const validElementsCount = groupedInvoiceItem.invoices.filter((item) =>
      selectedRows.includes(item.index)
    ).length;

    if (validElementsCount !== 0) {
      validGroupsCount += 1;
      modifiedGroup.push(groupedInvoiceItem);
    }
  });

  modifiedGroup.forEach(async (groupedInvoiceItem, index) => {
    const validElementsCount = groupedInvoiceItem.invoices.filter((item) =>
      selectedRows.includes(item.index)
    ).length;
    if (validElementsCount !== 0) {
      try {
        await getInitialTicketForPayment(
          accName,
          {
            store_id: groupedInvoiceItem.MonerisAPICredential.MonerisStoreID,
            api_token: groupedInvoiceItem.MonerisAPICredential.APIToken,
            checkout_id:
              groupedInvoiceItem.MonerisAPICredential.MonerisPaymentChechoutID,
          },
          groupedInvoiceItem.invoices,
          formData,
          paynow,
          currencyCode,
          monerisPaymentOptions,
          setLoading,
          endpoint,
          guid,
          corpid,
          token,
          sendrespons,
          selectedRows,
          invoiceItems,
          invoicesListForPut,
          data,
          setPaymentFailureReason
        );

        if (index === validGroupsCount - 1) {
          setISOpen(true);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setFailedIsOpen(true);
        console.log(err);
      }
    }
  });
};
