import React, { useEffect, useState } from "react";
import Visadebitcard from "../../assets/images/Visadebitcard.jpg";
import MasterCard from "../../assets/images/master card.jpg";
import AmricanExpress from "../../assets/images/amrican_express.jpg";

const CustomPaymentForm = ({
  handleChange,
  formData,
  handleSubmit,
  errors,
})=> {

  const [isChecked, setIsChecked] = useState(
    localStorage.getItem('storecard') === 'true' ? true : false
  );

  useEffect(() => {
    // Set initial value in localStorage if not already set
    if (localStorage.getItem('storecard') === null) {
      localStorage.setItem('storecard', 'false');
    }
  }, []);

  const handleCheckboxChanges = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue); // Update checkbox value when changed
    localStorage.setItem('storecard', newValue); // Update localStorage
  };
  // console.log('isChecked', isChecked);
  return (
    <div className="payment_information">
      <div className="payment_information_heading">
        <div className="payment_information_heading_details">
          <h2>Payment Information</h2>
          <div className="paymemt_card">
            <img src={Visadebitcard} alt="Visadebitcard" />
            <img src={MasterCard} alt="MasterCard" />
            <img src={AmricanExpress} alt="AmricanExpress" />
            <p>Basys</p>
          </div>
        </div>
      </div>
      <form>
        <div className="formdetails" id="formdetails">
          <input type="hidden" value="10" name="amount" />

          <label>Cardholder Name</label>
          <input
            autocomplete="cc-name"
            type="text"
            name="cardHolderName"
            value={formData.cardHolderName}
            onChange={handleChange}
            placeholder="Enter cardholder Name"
          />
          {errors.cardHolderName && (
            <p className="error">{errors.cardHolderName}</p>
          )}

          <label>Card Number</label>
          <input
            autocomplete="cc-number"
            type="tel"
            name="cardNumber"
            pattern="[\d| ]{16,22}"
            maxLength="19"
            value={formData.cardNumber}
            onChange={handleChange}
            placeholder="0000-0000-0000-0000"
          />
          {errors.cardNumber && <p className="error">{errors.cardNumber}</p>}

          <div className="card_cvv_expiry">
            <div className="card_expiry">
              <label>Expiry</label>
              <input
                autocomplete="cc-exp"
                type="tel"
                name="expiry"
                value={formData.expiry}
                onChange={handleChange}
                placeholder="MM/YY"
              />
              {errors.expiry && <p className="error">{errors.expiry}</p>}
            </div>
            <div className="card_cvv">
              <label>Cvv</label>
              <input
                autocomplete="cc-csc"
                type="tel"
                name="cvv"
                value={formData.cvv}
                onChange={handleChange}
                placeholder="CVV"
              />
              {errors.cvv && <p className="error">{errors.cvv}</p>}
            </div>
          </div>
          <button type="submit" onClick={handleSubmit}>
            Process Payment
          </button>
          {/* <div>
            <label>
              <input
                type="checkbox"
                name="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChanges}
              />
              Check me
            </label>
          </div> */}
        </div>

      </form>
    </div>
  );
};

export default CustomPaymentForm;
