import * as React from "react";
// Add popup box code start

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// Add popup box code end

const emails = ["username@gmail.com", "user02@gmail.com"];

export default function SimpleDialogDemo({
  open,
  handelCancel,
  image,
  title,
  decs,
  ButtonText,
  ButtonHandel,
  alt,
  color,
}) {
  return (
    <div style={{ borderRadius: "36px!important" }}>
      <React.Fragment>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="popupfix">
            <DialogContentText id="alert-dialog-description">
              <p>
                <i className="fa-solid fa-circle-exclamation"></i>
              </p>
              <img src={image} alt={alt} />
              <h2>{title}</h2>
              <span
                style={{
                  color: "#000000",
                }}
                dangerouslySetInnerHTML={{ __html: decs }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions id="update_btn" className="popupfix">
            <Button style={{ backgroundColor: color }} onClick={ButtonHandel}>
              {ButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}
