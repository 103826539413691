import axios from "axios";
import { API_BASE_URL } from "../config/constants";

export const getFormattedDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const calculateTotalForInvoices = (invoices, data, selectedRows) => {
  let total = 0;
  invoices.forEach((invoice) => {
    if (selectedRows.includes(invoice.index)) {
      if (data[invoice.index].hasOwnProperty("EditedAmountDue")) {
        total += parseFloat(data[invoice.index].EditedAmountDue);
      } else {
        total += parseFloat(data[invoice.index].AmountDue / 100);
      }
    }
  });
  total = total * 100;
  total = total.toFixed(2);
  return `${total}`;
};

export const validateForm = (data, setErrors) => {
  let errors = {};

  if (!data.cardHolderName.trim()) {
    errors.cardHolderName = "Cardholder name is required";
  }

  if (!data.cardNumber.trim()) {
    errors.cardNumber = "Card number is required";
  } else if (!validateCardNumber(data.cardNumber)) {
    errors.cardNumber = "Invalid card number";
  }

  // Validate Expiry Date
  if (!data.expiry.trim()) {
    errors.expiry = "Expiry date is required";
  } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(data.expiry)) {
    errors.expiry = "Invalid expiry date format (MM/YY)";
  } else {
    const [month, year] = data.expiry.split("/");
    const currentYear = new Date().getFullYear() % 100; // Get last two digits of the current year
    const currentMonth = new Date().getMonth() + 1; // Month is zero-based, so add 1
    if (
      parseInt(year, 10) < currentYear ||
      (parseInt(year, 10) === currentYear && parseInt(month, 10) < currentMonth)
    ) {
      errors.expiry = "Expiry date must be in the future";
    }
  }

  if (!data.cvv.trim()) {
    errors.cvv = "CVV is required";
  } else if (!/^\d{3,4}$/.test(data.cvv)) {
    errors.cvv = "Invalid CVV";
  }

  setErrors(errors);

  if (Object.keys(errors).length === 0) {
    return true;
  }

  return false;
};

export const validateCardNumber = (number) => {
  // Remove all non-digit characters
  const cleanedNumber = number.replace(/\D/g, "");

  // Luhn Algorithm to validate card number
  let sum = 0;
  let shouldDouble = false;

  for (let i = cleanedNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cleanedNumber[i]);

    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }

  return sum % 10 === 0;
};

export const storeLog = async (
  type,
  log,
  code,
  guid,
  corpid,
  action_type,
  action_description,
  action_status
) => {
  try {
    await axios.post(`${API_BASE_URL}/store-logs`, {
      type,
      log,
      code: `${code}`,
      pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
      guid: guid,
      corp_id: corpid,
      action_type,
      action_description,
      action_status,
    });
  } catch (err) {
    console.log(err);
  }
};
