//export const API_BASE_URL = "https://sds-pg-proxy.codepaperclient.com/api";
export const API_BASE_URL = "https://sdspayments.ca:12368/api";

export const bString =
  "x1W6sUDg3nRLXYhtAiyOBPOe0xFrOua==M48HtX/f4BGrL7dwUV34wD3L9MckrPnyCyjUQqlnaZ9JRu46f=qxR6pQXPt2g7vDMeQiIgkTGDY4pFN3ZdFkVj3g2y5c9WLsSHd2X1I0H3AYyC8/56iPQgfW=w+igC/Knf4DGNgaw1NJ6TXz+3GA6ImkqC/VZg==f2gKF6DeBh18sNxdWR1A+HD==qQPLL+O7iiRwq94NwUkTdi1s91Ua81XJyR4ulMEjg==CFBcYm/dXSdvmlzHLZtiD4WmsnbfgwUCF5d/v5=j+wur25eXKqvbyS61Y0fHy/VKehG5OPttcpw6yz==CD5XvZ/teH+6j9XQzfTsfrSVXUUgRuOoRNrB46E0fhpDb";

export const saveCardInitialTicketPayload = {
  txn_total: "00.00",
  environment: "qa",
  action: "preload",
  language: "en",
  ask_cvv: "Y",
  cust_id: "",
  dynamic_descriptor: "dyndesc",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};

export const saveCardReceiptPayload = {
  ticket: "",
  environment: "qa",
  action: "receipt",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};

export const paymentInitialTicketPayload = {
  txn_total: "",
  environment: "qa",
  action: "preload",
  language: "en",
  ask_cvv: "Y",
  dynamic_descriptor: "dyndesc",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};

export const paymentReceiptPayload = {
  ticket: "",
  environment: "qa",
  action: "receipt",
  pg_token: "uFHaKqSv25nYnBJ9Epx7kueWRGMa0yo",
};